import styled from "styled-components";

export default styled.div`
  font-family: "Antique Olive", sans-serif;
  z-index: 2;
  color: #39b1e6;
  text-align: left;

  & > h1 {
    font-weight: 900;
    font-size: 5rem;
  }

  & > h2 {
    font-weight: 300;
  }

  @media (max-width: 768px) {
    padding-top: 4rem;

    & > h1 {
      font-size: 2.5rem;
    }

    & > h2 {
      font-size: 1.2rem;
    }
  }
`;
