import React, { useEffect, useState, Fragment, useRef } from "react"
import Section from "./styled/Section"
import SectionHeading from "./styled/SectionHeading"
import Row from "./styled/Row"
import WaveUpwards from "./WaveUpwards"
import WaveDownwards from "./WaveDownwards"
import { getReviewsSection } from "../cms-queries"
import Flicking, { ViewportSlot } from "@egjs/react-flicking"
import ReviewCard from "./styled/ReviewCard"
import "@egjs/react-flicking/dist/flicking.css"

const monthShortnames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
]

const formatDate = (date) => {
  const dateObj = new Date(date)
  const month = monthShortnames[dateObj.getMonth()]
  const day = dateObj.getDate()
  const year = dateObj.getFullYear()

  return `${month} ${day}, ${year}`
}

const ReviewsSection = () => {
  const flicking = useRef(Flicking)
  const [reviews, setReviews] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getReviewsSection().then((reviewsSection) => {
      setReviews(reviewsSection)
      setLoading(false)
    })
  }, [])

  return (
    <Fragment>
      <WaveUpwards />
      <Section
        padding="2rem 0"
        style={{ minHeight: "40vh", marginTop: "-1rem" }}
        backgroundColor="#046E8F"
      >
        <SectionHeading style={{ flex: 1, color: "#fff" }}>
          Reviews
        </SectionHeading>
        <Row style={{ padding: "0 3rem" }}>
          <i
            className="fa-solid fa-chevron-left"
            style={{
              position: "absolute",
              fontSize: "2rem",
              left: "1rem",
              zIndex: 10,
              color: "#999",
              cursor: "pointer",
            }}
            onClick={() => {
              flicking.current.prev()
            }}
          ></i>
          <i
            className="fa-solid fa-chevron-right"
            style={{
              position: "absolute",
              fontSize: "2rem",
              right: "1rem",
              zIndex: 10,
              color: "#999",
              cursor: "pointer",
            }}
            onClick={() => {
              flicking.current.next()
            }}
          ></i>
          <Flicking
            ref={flicking}
            renderOnlyVisible={true}
            style={{ width: "100%" }}
            circular={true}
          >
            {!loading &&
              reviews.map((review, index) => (
                <ReviewCard key={index}>
                  <p style={{ lineHeight: "1.4rem" }}>
                    {review.body[0].children[0].text}
                  </p>
                  <h5 style={{ paddingTop: "0.5rem" }}>{review.author}</h5>
                  <h6 style={{ paddingTop: "0.2rem" }}>
                    {formatDate(review.date)}; {review.source}
                  </h6>
                </ReviewCard>
              ))}
            <ViewportSlot>
              <div className="flicking-pagination"></div>
            </ViewportSlot>
          </Flicking>
        </Row>
      </Section>
      <WaveDownwards />
    </Fragment>
  )
}

export default ReviewsSection
