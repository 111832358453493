import React from "react";
import { HeaderContainer } from "./styled";

const Header = () => {
  return (
    <HeaderContainer>
      <div className="image-container">
        <img
          src="./assets/nudge_paddle_logo.png"
          alt="nudge paddle product logo"
        />
      </div>
      <div className="spacer"></div>
    </HeaderContainer>
  );
};

export default Header;
