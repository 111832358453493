import styled from "styled-components";

export default styled.p`
  max-width: 60%;
  text-align: center;
  font-size: 1.3rem;
  color: #000;
  line-height: 2rem;
  font-family: Matter;
  font-weight: 400;

  @media (max-width: 768px) {
    max-width: 90%;
  }
`;
