import styled from "styled-components";

export default styled.section`
  display: flex;
  flex-direction: ${(props) => props.flexDirection || "column"};
  align-items: ${(props) => props.alignItems || "center"};
  justify-content: ${(props) => props.justifyContent || "center"};
  min-height: ${(props) => props.height || "auto"};
  position: relative;
  padding: ${(props) => props.padding || "0"};
  background-color: ${(props) => props.backgroundColor || "transparent"};
  width: ${(props) => props.width || "100%"};
  margin: ${(props) => (props.margin ? props.margin : "0")};
  overflow-x: hidden;
`;
