import React, { useEffect, useState } from "react";
import { Section, SectionHeading, Card, CardContainer } from "./styled";
import { urlFor } from "../imageBuilder";
import { getHowItWorksSection } from "../cms-queries";

const HowItWorksSection = () => {
  const [initialData, setInitialData] = useState(null);

  useEffect(() => {
    getHowItWorksSection().then((howItWorksSection) => {
      setInitialData(howItWorksSection);
    });
  }, []);

  return (
    <Section margin="8rem 0">
      <SectionHeading style={{ flex: 1 }}>{initialData?.title}</SectionHeading>
      <CardContainer>
        {initialData?.actions.map((action) => (
          <Card key={action._key}>
            {action.image && (
              <img src={urlFor(action.image).url()} alt="this ia an" />
            )}
            <div className="content">
              <h4>{action.title}</h4>
              <p>{action.body[0].children[0].text}</p>
            </div>
          </Card>
        ))}
      </CardContainer>
    </Section>
  );
};

export default HowItWorksSection;
