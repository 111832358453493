import styled from "styled-components";

export default styled.div`
  position: relative;
  width: 500px;
  height: 500px;
  box-shadow: inset 10px 10px 10px rgba(0, 0, 0, 0.05),
    15px 25px 10px rgba(0, 0, 0, 0.05), 15px 20px 20px rgba(0, 0, 0, 0.05),
    inset -10px -10px 15px rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  // border-radius: 57% 43% 37% 63% / 45% 52% 48% 55%;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    top: 35px;
    left: 25px;
    background-color: #fff;
  }

  &::after {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    top: 25px;
    left: 50px;
    background-color: #fff;
  }

  @media (max-width: 768px) {
    width: 300px;
    height: 300px;

    &::before {
      left: 60px;
    }

    &::after {
      left: 90px;
    }
  }

  @media (max-width: 320px) {
    width: 200px;
    height: 200px;
  }
`;
