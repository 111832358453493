import styled from "styled-components";

export default styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 0.5px solid #999;
  padding: 1rem;

  & > .title-row {
    display: flex;
    align-items: center;
  }

  & > .title-tab {
    width: 20px;
    height: 7px;
    background-color: #999;
    border-radius: 2px;
    margin-right: 0.5rem;
  }
`;
