import React, { useEffect, useState } from "react";
import { Section, SectionHeading, Row, Col } from "./styled";
import ReactPlayer from "react-player";
import { getLearnMoreSection } from "../cms-queries";

const LearnMoreSection = () => {
  const [initialData, setInitialData] = useState(null);

  useEffect(() => {
    getLearnMoreSection().then((learnMoreSection) => {
      setInitialData(learnMoreSection);
    });
  }, []);

  return (
    <Section margin="8rem 0">
      <SectionHeading padding="0 0 3rem 0">{initialData?.title}</SectionHeading>
      {initialData?.content.map((item, index) => (
        <Row
          height="50vh"
          width="80%"
          key={index}
          reverse={index % 2 !== 0 ? true : false}
        >
          <Col padding="0" justifyContent="center">
            <ReactPlayer
              controls={true}
              url={item.video}
              style={{ objectFit: "cover", width: "100%" }}
            />
          </Col>
          <Col padding="0" justifyContent="center">
            <h4 style={{ paddingBottom: "2rem" }}>{item.title}</h4>
            <p style={{ width: "80%", textAlign: "center" }}>
              {item.body[0].children[0].text}
            </p>
          </Col>
        </Row>
      ))}
    </Section>
  );
};

export default LearnMoreSection;
