import React from "react";
import { SocialIcon } from "react-social-icons";
import { Row, Section } from "./styled";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Section
      style={{
        backgroundColor: "#f2f2f2",
      }}
      height="20vh"
    >
      <Row>
        <h6 style={{ textAlign: "center" }}>
          COPYRIGHT © {currentYear} NUDGE PADDLE - ALL RIGHTS RESERVED.
        </h6>
        <SocialIcon url="https://web.facebook.com/nudge.paddle/?_rdc=1&_rdr" />
      </Row>
    </Section>
  );
};

export default Footer;
