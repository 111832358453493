import React, { useEffect, useState } from "react";
import { Section, SectionHeading, SectionBody } from "./styled";
import { getContentSection } from "../cms-queries";

const ContentSection = ({ title, margin }) => {
  const [initialData, setInitialData] = useState(null);

  useEffect(() => {
    getContentSection(title).then((contentSection) => {
      setInitialData(contentSection);
    });
  }, [title]);

  return (
    <Section margin={margin}>
      <SectionHeading>{initialData?.title || ""}</SectionHeading>
      <SectionBody>{initialData?.body[0].children[0].text}</SectionBody>
    </Section>
  );
};

export default ContentSection;
