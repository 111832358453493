import styled from "styled-components";

export default styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 4px 20px 0 rgba(34, 68, 123, 0.2);

  & > img {
    max-width: 100%;
    height: auto;
    border-radius: 20px 20px 0 0;
  }

  & > .content {
    padding: 2rem;

    & > h4 {
      padding-bottom: 1rem;
      font-family: "Antique Olive", sans-serif;
    }

    & > p {
      font-family: Matter, sans-serif;
      line-height: 1.3rem;
    }
  }
`;
