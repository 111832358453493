import styled from "styled-components";

export default styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

  & > .image-container {
    width: 100%;
    flex: 1;
    height: auto;
    margin: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: center;

    & > img {
      width: 100%;
      max-width: 80px;
    }
  }

  & > div.spacer {
    flex: 1;
    display: inherit;
  }

  @media (max-width: 768px) {
    justify-content: flex-start;
    padding: 0 2rem;

    & > img {
      max-width: 50px;
    }

    & > div.spacer {
      display: none;
    }
  }
`;
