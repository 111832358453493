import React, { useState, Fragment } from "react"
import { urlFor } from "../imageBuilder"
import { Row, Col, Button, AccordionTitle, StoreButton } from "./styled"

const Accordion = ({ store }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div
      style={{
        width: "80%",
        margin: "0.5rem 0",
      }}
    >
      <AccordionTitle onClick={() => setIsOpen(!isOpen)}>
        <div className="title-row">
          <div className="title-tab"></div>
          <div>{store.province}</div>
        </div>
        <div>{isOpen ? "-" : "+"}</div>
      </AccordionTitle>
      <div style={{ margin: "0.5rem 0" }}>
        {isOpen && (
          <Row>
            {store.stores?.length > 0 ? (
              store.stores.map((store, index) => (
                <Col justifyContent="center" key={index}>
                  {store.storelogo ? (
                    <div>
                      {store.storecity && (
                        <p
                          style={{
                            color: "#999",
                            fontWeight: "600",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          {store.storecity}
                        </p>
                      )}
                      <a href={store.storeurl} target="_blank" rel="noreferrer">
                        <img
                          src={urlFor(store.storelogo).url()}
                          alt={store.storename}
                          style={{ marginTop: "1rem" }}
                          className="store-logo"
                        />
                      </a>
                    </div>
                  ) : (
                    <StoreButton>
                      <p>{store.storecity || ""}</p>
                      <Button
                        href={store.storeurl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <h5 style={{ textAlign: "center" }}>
                          {store.storename}
                        </h5>
                        {store.storeurl && (
                          <Fragment>
                            <div className="vertical-line"></div>
                            <i className="fa-solid fa-arrow-up-right-from-square"></i>
                          </Fragment>
                        )}
                      </Button>
                    </StoreButton>
                  )}
                </Col>
              ))
            ) : (
              <Col justifyContent="center" style={{ paddingTop: "1rem" }}>
                <h3 style={{ textAlign: "center", color: "#999" }}>
                  Coming Soon!
                </h3>
              </Col>
            )}
          </Row>
        )}
      </div>
    </div>
  )
}

export default Accordion
