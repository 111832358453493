import SanityClient from "./client";

export const getHero = async () => {
  const hero = await SanityClient.fetch('*[_type == "herosection"]');
  return hero[0];
};

export const getContentSection = async (title) => {
  const contentSection = await SanityClient.fetch(
    `*[_type == "contentsection" && title == "${title}"]`
  );
  return contentSection[0];
};

export const getHowItWorksSection = async () => {
  const howItWorksSection = await SanityClient.fetch(
    `*[_type == "howsection"]`
  );
  return howItWorksSection[0];
};

export const getLearnMoreSection = async () => {
  const learnMoreSection = await SanityClient.fetch(
    '*[_type == "learnsection"]'
  );
  return learnMoreSection[0];
};

export const getBuyOnlineSection = async () => {
  const buyOnlineSection = await SanityClient.fetch('*[_type == "buysection"]');
  return buyOnlineSection[0];
};

export const getStoresSection = async () => {
  const storesSection = await SanityClient.fetch(
    `*[_type == "storessection"]{
      order,
      province,
      stores
    } | order(order asc)`
  );
  return storesSection;
};

export const getReviewsSection = async () => {
  const reviewsSection = await SanityClient.fetch(
    '*[_type == "reviewsection"]'
  );
  return reviewsSection;
};
