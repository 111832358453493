import styled from "styled-components"

export default styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${(props) => props.justifyContent || "space-around"};
  flex: 1;
  padding: ${(props) => props.padding || "0 2rem"};
  height: 100%;
  width: ${(props) => props.width || "100%"};
  text-align: center;

  .store-logo {
    width: 10rem;
  }
`
