import styled from "styled-components"

export default styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  flex-direction: ${(props) => (props.reverse ? "row-reverse" : "row")};
  align-items: ${(props) => props.alignItems || "center"};
  min-height: ${(props) => props.height || "auto"};
  justify-content: space-around;
  flex: ${(props) => props.flex || "1"};
  position: relative;
  padding: ${(props) => props.padding || "0"};
  background-color: ${(props) => props.backgroundColor || "transparent"};
  width: ${(props) => props.width || "100%"};

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 1rem;
    justify-content: center;
    gap: 0;

    & > * {
      margin: 1rem 0;
    }
  }
`
