import React, { useEffect, useState } from "react";
import { Section, ScrollIcon, Row, Drop, HeroText } from "./styled";
import Loader from "./Loader";
import { getHero } from "../cms-queries";

const HeroSection = () => {
  const [initialData, setInitialData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getHero().then((hero) => {
      setInitialData(hero);
      setLoading(false);
    });
  }, []);

  return (
    <Section
      padding="0"
      height="100vh"
      alignItems="flex-start"
      style={{
        backgroundColor: "#f9f9f9",
        height: "100vh",
        overflowX: "hidden",
      }}
    >
      {loading ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            overflow: "hidden",
          }}
        >
          <Loader />
        </div>
      ) : (
        <Row>
          <HeroText>
            <h2>{initialData?.subtitle}</h2>
            <h1>{initialData?.title}</h1>
            <ScrollIcon className="fa-solid fa-chevron-down"></ScrollIcon>
          </HeroText>
          <Drop style={{ overflowX: "hidden" }}>
            <img
              src="./assets/hero_image.jpg"
              alt="nudge paddle product logo"
              style={{
                height: "100%",
                width: "auto",
              }}
            />
          </Drop>
        </Row>
      )}
    </Section>
  );
};

export default HeroSection;
