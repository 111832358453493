import styled from "styled-components";

export default styled.a`
  text-decoration: none;
  background-color: #000;
  color: #fff;
  padding: 1rem 2rem;
  border-radius: 20px;
  font-size: 1.2rem;
  font-family: Matter, sans-serif;
  font-weight: 600;
  display: flex;

  & > .vertical-line {
    width: 0.5px;
    border: 1px solid #999;
    background-color: #fff;
    margin: 0 1rem;
  }
`;
