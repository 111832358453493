import React, { useRef } from "react";
import Section from "./styled/Section";
import SectionHeading from "./styled/SectionHeading";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";
import styled from "styled-components";

const Form = styled.form`
  width: 50%;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

const GetInTouchSection = () => {
  const form = useRef();

  const sendMail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_6ica54b",
        "template_aqlkb8s",
        form.current,
        "XnJQWtEwA4FfEq8HN"
      )
      .then(
        (result) => {
          toast.success("Message sent!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();
  };

  return (
    <Section margin="4rem 0">
      <SectionHeading>Get in touch</SectionHeading>
      <Form ref={form} onSubmit={sendMail}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div
            style={{
              margin: "1rem 0",
              flex: 1,
              marginRight: "0.5rem",
            }}
          >
            <h5
              style={{
                paddingBottom: "0.5rem",
                paddingLeft: "0.5rem",
              }}
            >
              Name *
            </h5>
            <input
              type="text"
              name="user_name"
              style={{
                padding: "1rem 2rem",
                border: "1px solid #333",
                width: "100%",
                borderRadius: "1rem",
              }}
            ></input>
          </div>
          <div
            style={{
              margin: "1rem 0",
              flex: 1,
              marginRight: "0.5rem",
            }}
          >
            <h5
              style={{
                paddingBottom: "0.5rem",
                paddingLeft: "0.5rem",
              }}
            >
              Contact Number
            </h5>
            <input
              name="user_cell"
              type="text"
              style={{
                padding: "1rem 2rem",
                border: "1px solid #333",
                width: "100%",
                borderRadius: "1rem",
              }}
            ></input>
          </div>
          <div
            style={{
              margin: "1rem 0",
              flex: 1,
              marginRight: "0.5rem",
            }}
          >
            <h5
              style={{
                paddingBottom: "0.5rem",
                paddingLeft: "0.5rem",
              }}
            >
              Email Address *
            </h5>
            <input
              name="user_email"
              type="text"
              style={{
                padding: "1rem 2rem",
                border: "1px solid #333",
                width: "100%",
                borderRadius: "1rem",
              }}
            ></input>
          </div>
          <div
            style={{
              margin: "1rem 0",
              flex: 1,
              marginRight: "0.5rem",
            }}
          >
            <h5
              style={{
                paddingBottom: "0.5rem",
                paddingLeft: "0.5rem",
              }}
            >
              Message *
            </h5>
            <textarea
              name="message"
              style={{
                padding: "1rem 2rem",
                border: "1px solid #333",
                width: "100%",
                borderRadius: "1rem",
              }}
            ></textarea>
          </div>
          <button
            type="submit"
            style={{
              padding: "1rem 2rem",
              textAlign: "center",
              backgroundColor: "#333",
              color: "#fff",
              width: "100%",
              border: "none",
              borderRadius: "1rem",
              cursor: "pointer",
            }}
          >
            Nudge Your Message
          </button>
        </div>
      </Form>
    </Section>
  );
};

export default GetInTouchSection;
