import React, { useEffect, useState } from "react"
import { getBuyOnlineSection, getStoresSection } from "../cms-queries"
import { Section, SectionHeading, SectionBody } from "./styled"
import Accordion from "./Accordion"

const BuyOnlineSection = () => {
  const [initialData, setInitialData] = useState(null)
  const [storesData, setStoresData] = useState([])

  useEffect(() => {
    getBuyOnlineSection().then((buyOnlineSection) => {
      setInitialData(buyOnlineSection)
    })

    getStoresSection().then((storesSection) => {
      setStoresData(storesSection)
      console.log("storesSection", storesSection)
    })
  }, [])

  return (
    <Section height="30vh" margin="4rem 0">
      <SectionHeading padding="0 0 1rem 0">{initialData?.title}</SectionHeading>
      <SectionBody style={{ paddingBottom: "2rem" }}>
        {initialData?.subtitle}
      </SectionBody>
      {storesData?.map((store, index) => {
        const storeSortedByCityAlphabetically = store.stores?.sort((a, b) => {
          if (a.storecity < b.storecity) {
            return -1
          }
          if (a.storecity > b.storecity) {
            return 1
          }
          return 0
        })

        return (
          storeSortedByCityAlphabetically?.length > 0 && (
            <Accordion key={index} store={store} />
          )
        )
      })}
    </Section>
  )
}

export default BuyOnlineSection
