import styled from "styled-components";

export default styled.div`
  width: 500px;
  height: 250px;
  background-color: #f2f2f2;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem;
  margin: 1rem;

  & > p {
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
  }

  & > h6 {
    padding-top: 1rem;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: auto;

    & > p {
      -webkit-line-clamp: 3;
      font-size: 0.8rem;
    }
  }
`;
