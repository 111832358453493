import styled from "styled-components";

export default styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;

  & > p {
    color: #999;
    font-weight: 600;
    padding: 0;
    margin: 0;
    padding-bottom: 0.5rem;
  }
`;
