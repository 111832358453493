import React from "react";
import Footer from "../components/Footer";
import ContentSection from "../components/ContentSection";
import HeroSection from "../components/HeroSection";
import HowItWorksSection from "../components/HowItWorksSection";
import LearnMoreSection from "../components/LearnMoreSection";
import BuyOnlineSection from "../components/BuyOnlineSection";
import ReviewsSection from "../components/ReviewsSection";
import GetInTouchSection from "../components/GetInTouchSection";
import Header from "../components/Header";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const HomePage = () => {
  return (
    <main>
      <ToastContainer />
      <Header />
      <HeroSection />
      <ContentSection title="Welcome" margin="8rem 0" />
      <ContentSection title="Nudge Paddle" margin="8rem 0" />
      <HowItWorksSection />
      <LearnMoreSection />
      <BuyOnlineSection />
      <ReviewsSection />
      <GetInTouchSection />
      <Footer />
    </main>
  );
};

export default HomePage;
